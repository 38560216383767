'use strict';

/*******************************************************************************************/
class UxrOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
        };
        
        this.onCloseMessage = this.onCloseMessage.bind(this);
        
        UI.guiOverlay = this;
    }
    
    /*---------------------------------------------------------------------*/
    render() {
        let o = this;
        let messages = [];
        _.forEach(this.state.messages, function(message) {
            messages.push(message.reactNode);
        });
        
        if (messages.length == 0) {
            return null;
            
        } else {
            return (
                <div className="overlay">
                    {messages}
                </div>
            );
        }
    }
    
    /*---------------------------------------------------------------------*/
    onCloseMessage(event) {
        let node = jQuery(event.target);
        let key = node.closest('[data-key]').attr('data-key');
        this.removeMessage(key);
    }
    
    /*---------------------------------------------------------------------*/
    addMessage(message) {
        this.setState((prevState, props) => {
            let messages = _.defaults({}, prevState.messages);
            messages[message.key] = message;
            return {
                messages: messages,
            };
        });
        
        return _.bind(this.removeMessage, this, message.key);
    }
    
    /*---------------------------------------------------------------------*/
    removeMessage(key) {
        this.setState((prevState, props) => {
            let messages = _.defaults({}, prevState.messages);
            delete messages[key];
            return {
                messages: messages,
            };
        });
    }
    
    /*---------------------------------------------------------------------*/
    basicMessageFactory() {
        let message = {};
        
        message.time = Date.now();
        message.dateString = (new Date(message.time)).toTimeString();
        message.key = IdGenerator.produce('overlayMessage_');
        message.reactNode = null;
        
        return message;
    }
    
    /*---------------------------------------------------------------------*/
    /*---------------------------------------------------------------------*/
    /*---------------------------------------------------------------------*/
    notifyUser_processStarted(description) {
        let o = this;
        
        let message = this.basicMessageFactory();
        message.reactNode = (
            <div className="overlayMessage overlayMessage_info" key={message.key} data-key={message.key}>
                <div className="overlayMessageTime">{message.dateString}</div>
                <div className="overlayMessageTitle">...in progress...</div>
                <div className="overlayMessageText">
                    {description}
                </div>
            </div>
        );
        
        return this.addMessage(message);
    }
    
    /*---------------------------------------------------------------------*/
    notifyUser_processBlocked(description) {
        let o = this;
        
        let message = this.basicMessageFactory();
        message.reactNode = (
            <div className="overlayMessage overlayMessage_error" key={message.key} data-key={message.key}>
                <div className="overlayMessageCloseIcon" onClick={o.onCloseMessage}>&times;</div>
                <div className="overlayMessageTime">{message.dateString}</div>
                <div className="overlayMessageTitle">Process blocked!</div>
                <div className="overlayMessageText">
                    {description}
                    <hr />
                    This should never happen and probably means you will have to reload, loosing your last changes. 
                </div>
            </div>
        );
        
        this.addMessage(message);
    }
} //class

